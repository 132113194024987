import Topbar from '@/components/topbar/topbar_component.vue';
import Navbar from '@/components/navbar/navbar_component.vue';
import LeftView from '@/components/DraftLeftView/draft_leftview_component.vue';
import InstantMeet from '@/components/InstantMeetDetails/instant_meeting_component.vue';
import Popover from '@/components/popovers/popover_component.vue';
import ChangeLogView from '@/components/changelog/changelog_component.vue';
import moment from 'moment';
import 'moment-timezone';

export default{
    name : 'DraftMeeting',
    components:{
        Topbar,
        Navbar,
        LeftView,
        InstantMeet,
        Popover,
        ChangeLogView
    },
    data(){
        return{
            meeting_data : {},
            meeting_popup : false,
            hover_index : 0,
            minutes_index :0,
            meeting_agendas : [],
            focus_agenda_index : 0,
            participates_obj : [],
            meeting_endtime:'',
            meeting_starttime:'',
            all_groups : [],
            group_selected : true,
        }
    },
   
    async created(){
        await this.getMeetDetails();
        this.remove_enter_func(document.getElementById("select_datepicker"))
        this.remove_enter_func(document.getElementById("select_start_time"))
        this.remove_enter_func(document.getElementById("select_end_time"))
    },

    methods: {
        toast(variant, title, message, append = false) {
            this.counter++
            this.$bvToast.toast(message, {
              title: title,
              solid: true,
              variant : variant,
              appendToast: append
            })
        },
        
        remove_enter_func(input){
            input.addEventListener('keypress', (e) => {
                if (e.which == this.Constants.ENTER) e.preventDefault();
            });
        },

        async getMeetDetails(){
            let meeting_id = this.$route.params.meeting_id;
            await this.$store.dispatch('getMeetingMinutes',[this.$store.state.api, { "meeting_id": meeting_id}]);
            if(Object.keys(this.$store.state.meeting_minutes_details).length > 0){
                this.meeting_data = this.$store.state.meeting_minutes_details;
                this.meeting_agendas = this.$store.state.meeting_minutes_details.agenda;
                this.meeting_endtime = this.meeting_data.meeting.meeting_end_time;
                this.meeting_endtime = moment(this.meeting_endtime).format("HH:mm:ss")
                this.meeting_starttime = this.meeting_data.meeting.meeting_start_time;
                this.meeting_starttime = moment(this.meeting_starttime).format("HH:mm:ss")
            } 
        },

        async update_meeting(e,edit_type){
            let meeting_obj = {};
            if(edit_type == 'title'){
                let meet_title = e.srcElement.innerText != '' ? e.srcElement.innerText : "Instant Meeting - "+ moment(this.meeting_data.meeting.meeting_start_time).utc().tz(this.meeting_data.meeting.meeting_timezone).format("DD MMM  - h:mmA");
                meeting_obj = {
                    "id":this.meeting_data.meeting._id,
                    "meeting_title":  meet_title     
                              }
            }else if(edit_type == 'objective'){
                meeting_obj = {
                    "id":this.meeting_data.meeting._id,
                    "meeting_objective": e.srcElement.innerHTML     
                }
            }else if(edit_type == 'date_change'){
                meeting_obj = {
                    "id":this.meeting_data.meeting._id,
                    "meeting_date": e ,
                    'meeting_timezone' : this.Constants.MEET_TIME_ZONE   
                }
            }else if(edit_type == 'start_time_change'){
                let starttime = moment(e,'HH:mm:ss').format('HH:mm');
                let meetdate = moment(this.meeting_data.meeting.meeting_date).format("YYYY-MM-DD")
                meeting_obj = {
                    "id":this.meeting_data.meeting._id,
                    "meeting_start_time": meetdate +' '+ starttime ,
                    'local_timezone' : this.Constants.MEET_TIME_ZONE  
                }   
            }else if(edit_type == 'end_time_change'){
                let endtime = moment(e,'HH:mm:ss').format('HH:mm');
                let meetdate = moment(this.meeting_data.meeting.meeting_date).format("YYYY-MM-DD")
                meeting_obj = {
                    "id":this.meeting_data.meeting._id,
                    "meeting_end_time": meetdate +' '+ endtime  ,
                    'local_timezone' : this.Constants.MEET_TIME_ZONE   
                }
            }else if(edit_type == 'date_input_change'){
                let original_date_input = e.srcElement.innerText;
                if(e.srcElement.innerText ==''){
                    e.srcElement.innerText = moment().add(1, 'days').format("ddd MMM DD, YYYY");
                }else if(moment(e.srcElement.innerText).format("DD MMM, YYYY") == "Invalid date"){
                    e.srcElement.innerText = moment(e.srcElement.innerText, "DD MM YYYY").format("ddd MMM DD, YYYY")
                }else{
                    e.srcElement.innerText = moment(e.srcElement.innerText).format("ddd MMM DD, YYYY");
                }
                if(original_date_input.match(/[a-zA-Z]/) != null){
                    if(!Number.isInteger(parseInt(original_date_input.slice(-2)))){
                        e.srcElement.innerText = moment(original_date_input, "DD MMM YYYY").format("ddd MMM DD, YYYY")
                    }else if(!Number.isInteger(parseInt(original_date_input.split("").reverse().join("").slice(-2)))){
                        e.srcElement.innerText = moment(original_date_input, "MMM DD YYYY").format("ddd MMM DD, YYYY")
                    }
                    if(Number.isInteger(parseInt(original_date_input.slice(-2))) || Number.isInteger(parseInt(original_date_input.slice(-1)))){

                        if(Number.isInteger(parseInt(original_date_input.slice(-4)))){
                            e.srcElement.innerText = moment(original_date_input).format('ddd MMM DD YYYY');
                        }else{
                            e.srcElement.innerText = moment(moment(original_date_input,'MMM DD').toString()).format("ddd MMM DD, YYYY");
                        }
                        
                    }else if(Number.isInteger(parseInt(original_date_input.split("").reverse().join("").slice(-2)))){
                        e.srcElement.innerText = moment(original_date_input, "DD MMM YYYY").format("ddd MMM DD, YYYY")
                    }

                    }else if(original_date_input.replace(/\D/g, "").length <= 4 && original_date_input != ""){
                        e.srcElement.innerText = moment(original_date_input, "DD MM YYYY").format("ddd MMM DD, YYYY")

                        if(e.srcElement.innerText == "Invalid date"){
                            e.srcElement.innerText = moment(original_date_input, "MM DD YYYY").format("ddd MMM DD, YYYY")
                        }
                    }
               meeting_obj = {
                   "id":this.meeting_data.meeting._id,
                   "meeting_date": e.srcElement.innerText ,
                   'meeting_timezone' : this.Constants.MEET_TIME_ZONE   
               }   
           }else if(edit_type == 'start_time_input_change'){
                if(e.srcElement.innerText == ""){
                    e.srcElement.innerText = moment(this.meeting_data.meeting.meeting_start_time,'HH:mm:ss').format('hh:mm A')
                }else if(e.srcElement.innerText){
                    e.srcElement.innerText = moment(e.srcElement.innerText,'HH:mm:ss').format('hh:mm A')
                }
                let starttime = moment(e.srcElement.innerText,'HH:mm:ss').format('HH:mm')
                let meetdate = moment(this.meeting_data.meeting.meeting_date).format("YYYY-MM-DD")
                meeting_obj = {
                    "id":this.meeting_data.meeting._id,
                    "meeting_start_time": meetdate +' '+ starttime ,
                    'local_timezone' : this.Constants.MEET_TIME_ZONE    
                }
                
            }else if(edit_type == 'end_time_input_change'){
                if(e.srcElement.innerText == ""){
                    e.srcElement.innerText = moment(this.meeting_data.meeting.meeting_start_time,'HH:mm:ss').format('hh:mm A')
                }else if(e.srcElement.innerText){
                    e.srcElement.innerText = moment(e.srcElement.innerText,'HH:mm:ss').format('hh:mm A') 
                }
                let endtime = moment(e.srcElement.innerText,'HH:mm:ss').format('HH:mm');
                let meetdate = moment(this.meeting_data.meeting.meeting_date).format("YYYY-MM-DD")
                meeting_obj = {
                    "id":this.meeting_data.meeting._id,
                    "meeting_end_time": meetdate +' '+ endtime  ,
                    'local_timezone' : this.Constants.MEET_TIME_ZONE   
                }
            }
            if(meeting_obj.meeting_date){
                document.getElementById("select_datepicker").innerHTML = moment(meeting_obj.meeting_date).format("ddd MMM DD, YYYY")
            }
            if(meeting_obj.meeting_start_time){
                document.getElementById("select_start_time").innerHTML = moment(meeting_obj.meeting_start_time).format('hh:mm A')
            }
            if(meeting_obj.meeting_end_time){
                document.getElementById("select_end_time").innerHTML = moment(meeting_obj.meeting_end_time).format('hh:mm A')
            }
            await this.$store.dispatch('CrudMeeting',[this.$store.state.api, meeting_obj]);
            await this.getMeetDetails(); 
        },

        check_date_time(){
            var datevalue =  document.getElementById("select_datepicker").textContent
            var time_start_value = document.getElementById("select_start_time").textContent
            var time_end_value = document.getElementById("select_end_time").textContent
            if(datevalue == "Invalid date"){
                document.getElementById('select_datepicker').style.borderColor = "red";
                this.$refs.datebar.focus();
                document.getElementById("select_datepicker").innerHTML = ""
            }else if(time_start_value == "Invalid date" ){
                document.getElementById('select_start_time').style.borderColor = "red";
                this.$refs.time_start_bar.focus();
                document.getElementById("select_start_time").innerHTML = ""
            }else if(time_end_value == "Invalid date"){
                document.getElementById('select_end_time').style.borderColor = "red";
                this.$refs.time_end_bar.focus();
                document.getElementById("select_end_time").innerHTML = ""
            }
            else{
                document.getElementById('select_datepicker').style.borderColor = "blue";
                document.getElementById('select_start_time').style.borderColor = "blue";
                document.getElementById('select_end_time').style.borderColor = "blue";
            }
        },

        async agenda_count_change(type,index=''){

            this.$store.state.loader_popup = true;

            await this.getMeetDetails();

            if(type == "add"){
                let agendaObj = {
                    "meeting_id":this.meeting_data.meeting._id,
                        "agenda":{
                            "operation_type":1
                        }
                    }

                await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,agendaObj]);
                
                if(Object.keys(this.$store.state.added_agenda_data).length > 0){
                    let minutes_obj = {
                            "meeting_id": this.meeting_data.meeting._id,
                            "minutes":{
                                "agenda_id": this.$store.state.added_agenda_data.agenda._id,
                                "operation_type":1,
                                "minute_type": this.Constants.TASK_TYPE.TYPE_5,
                                "parameters" : {
                                    "asignee_id" : "",
                                    "due_date": moment().format("YYYY-MM-DD")
                                }
                            }
                        }

                        if(this.meeting_data.meeting.group_id){
                            Object.assign(minutes_obj,{"group_id": this.meeting_data.meeting.group_id});
                            Object.assign(minutes_obj,{"group_name": this.meeting_data.meeting.group_name});
                        }

                    await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,minutes_obj]);
                }
               
            }else{
                let agendaObj = {
                    "meeting_id":this.meeting_data.meeting._id,
                        "agenda":{
                            "id":this.meeting_agendas[index]._id,
                            "operation_type":3
                        }
                    }

                await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,agendaObj]);
            }

            await this.getMeetDetails();

            this.$store.state.loader_popup = false;

            if(type == "add"){
                this.focus_agenda_index = this.meeting_agendas.length-1;
            }
            this.meeting_popup = false;
        },

        async instant_meet_count_change(index,data_index){

            await this.getMeetDetails();

            if(index== 'null'){
                index = this.hover_index;
            }
            let delete_obj = {
                "meeting_id":this.meeting_agendas[index].meeting_id,
                "minutes":{
                    "id":this.meeting_agendas[index].minutes[data_index]._id,
                    "operation_type":3
                }
            }

            await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,delete_obj]);

            await this.getMeetDetails();

            this.meeting_popup = false;
        },

        async publish_meeting(type){

            let meeting_obj = {
                "id": this.meeting_data.meeting._id,
                "status": type,
            }

            await this.$store.dispatch('CrudMeeting',[this.$store.state.api, meeting_obj]);

            this.$router.push('/workbench');
        },

        confirm_popup_function(type){
            switch(type){
                case 'publish':
                    this.publish_meeting('Published');
                    break;
                case 'draft':
                    this.publish_meeting('Draft');
                    break;
                case 'minute':
                    this.instant_meet_count_change(this.hover_index,this.minutes_index);
                    break;
                case 'agenda':
                    this.agenda_count_change('delete',this.hover_index)
                    break;
                case 'discard' :
                    this.$store.commit("deleteMeeting",{"meeting_id":this.$route.params.meeting_id});
                    this.publish_meeting('Discard')
            }
        },

        remove_meeting_pop_close(){
            this.meeting_popup = false; 
        },
        
        async publish_meeting_popup(){

            await this.getMeetDetails();
               
            if(this.meeting_data.meeting.group_id){
                this.open_popup_type = "close_meeting_popup"; 
                this.meeting_popup = true; 
               
            }else{
                this.group_selected = false;
                this.meeting_popup = false; 
            }
        },

        open_popup_type_function(value){

            if(value[0] == 'delete_minute'){
                this.open_popup_type = "delete_popup_single_row_minutes";

                if(value[3] > 1){
                    this.meeting_popup = true;
                }
                
            }else if(value[0] == 'delete_agenda'){
                this.open_popup_type = "delete_popup_agenda_minutes";
                this.meeting_popup = true;
            }else if(value[0] == 'add_group'){
                this.open_popup_type = 'view_group';
                this.meeting_popup = true;
            }

            this.hover_index = value[1];
            this.minutes_index = value[2];
        },

        async add_group(val){
            let group_obj = {
                    "org_id": this.$store.state.org_id,     
                    "group_name": val[1],     
                }

            await this.$store.dispatch('SaveGroup',[this.$store.state.api, group_obj]);
           
            await this.$store.dispatch('getGroups',[this.$store.state.api,{"org_id" : this.$store.state.org_id}]);
            this.all_groups = this.$store.state.groups_obj;
            this.meeting_popup = false;
            this.toast('info','Success','Added group successfully'); 
        },

        async download() {
            let meeting_id = this.$route.params.meeting_id;
            let result = await this.$store.dispatch('getMeetingMinutesDownload', [this.$store.state.api, {"meeting_id": meeting_id, "local_timezone": this.Constants.MEET_TIME_ZONE}]);
            if (result.message.includes('success')) {
                window.location.href = this.$store.state.api + 'stylesheets/meeting_' + meeting_id + '.docx';
            }
        }
    },

    filters:{
        time_24hr(date_time){
            return moment(date_time,"HH:mm:ss").format("hh:mm A");
        },

        day_month_date(date_time,time_zone){
            return moment(date_time).utc().tz(time_zone).format("ddd MMM DD, YYYY");
        },

        regular_format(date){
            return moment(date ,'YYYY-MM-DD HH:mm:ss').format("DD MMM, YYYY");
        },
    }
}