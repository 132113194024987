import {mapGetters} from 'vuex';
import { bus } from '../../main';
import Multiselect from 'vue-multiselect';

export default{
    name : 'DraftLeftViewComponent',
    components:{
        Multiselect
    },

    data(){
        return{
            search : '',
            add_mem_firstname : '',
            add_mem_lastname : '',
            add_mem_mail : '',
            members_selected: [],
            meeting_participates: [],
            participates_attended : [],
            add_member_select : [],
            add_member_error : '',
            meeting_data : {},
            all_groups : [],
            meet_group : '',
            select_grp_error : false
        }
    },

    props: {
        participates : Array,
        groups_obj : Array,
        grp_select : Boolean,
    },

    watch:{
        groups_obj(val){
            this.all_groups = val;
            let added_grp_index = this.all_groups.findIndex(grp => grp._id == this.$store.state.save_group_data._id);
            this.meet_group = this.all_groups[added_grp_index];
            this.save_meeting_group();
        },

        grp_select(val){
            if(!val){
                this.$refs.group_ref.isOpen = true;
                this.select_grp_error = true;
            }
        }
    },

    async created(){
        await this.getMeetParticipants();
        await this.$store.dispatch('getOrgMembers',[this.$store.state.api, this.$store.state.org_id]);
        this.participates_attended = this.$store.getters.members_attended;
        this.meeting_data = this.$store.state.meeting_minutes_details.meeting;
        this.org_members_selected();

        await this.$store.dispatch('getGroups',[this.$store.state.api,{"org_id" : this.$store.state.org_id}]);
        this.all_groups = this.$store.state.groups_obj;

        if(this.meeting_data.group_id != ''){
            let group_index = this.all_groups.findIndex(group => group._id == this.meeting_data.group_id);
            if(group_index > -1){
                this.meet_group = this.all_groups[group_index];
            }
        }

    },

    mounted(){
        this.participatesToParent();
    },

    computed:{
        ...mapGetters({search_member_result :'search_org_members',mail_exist_in_members :'mail_exist_in_members',members_attended : 'meet_members_attended'}),

        filteredData(){
            if(this.search.length > 0){
                return this.search_member_result(this.search);
            }
        }
    },

    methods: {
        org_members_selected(){
            this.add_member_select =  this.meeting_participates.reduce((a,e)=>{
                    a.push(e.org_member_id);
                return a;
            },[])
        },

        async save_meeting_group(){
            let meeting_obj = {
                "id": this.$route.params.meeting_id,
                "group_id": this.meet_group._id,
                "group_name": this.meet_group.group_name,
            }
            await this.$store.dispatch('CrudMeeting',[this.$store.state.api, meeting_obj]);
            this.select_grp_error = false;
            this.$emit('group_select_status');
        },

        async getMeetParticipants(){
            let meeting_id = this.$route.params.meeting_id;
            await this.$store.dispatch('getMeetingParticipants',[this.$store.state.api, { "meeting_id": meeting_id}]);
            this.meeting_participates= this.$store.state.meeting_participants;
        },

        toast(variant, title, message, append = false) {
            this.counter++
            this.$bvToast.toast(message, {
              title: title,
              solid: true,
              variant : variant,
              appendToast: append
            })
        },

        async add_participate(e){
            this.$store.state.loader_popup = true;
            if(e.target.checked){

                let add_participate_data = {
                    "meeting_id":this.meeting_data._id,
                    "org_member_id": e.target.value
                }

                await this.$store.dispatch('addParticipatestoMeeting',[this.$store.state.api,add_participate_data]);
                this.$store.commit('updateAttendance',{"participant_id": this.$store.state.added_participate._id,"attendance_status": e.target.checked});
                this.participates_attended.push(this.$store.state.added_participate._id);
                sessionStorage.setItem('members_attendance', JSON.stringify(this.participates_attended));
                this.toast('info','Success','Added participant successfully');
            }else{
               
                let member_index = this.meeting_participates.findIndex(member => member.org_member_id == e.target.value);
                await this.$store.dispatch('deleteParticipate',[this.$store.state.api,{"participant_id": this.meeting_participates[member_index]._id,"meeting_id": this.meeting_data._id}]);
                let  index = this.participates_attended.indexOf(this.$store.state.added_participate._id);
                if (index > -1) {
                    this.participates_attended.splice(index, 1);
                }
                sessionStorage.setItem('members_attendance', JSON.stringify(this.participates_attended));
                this.toast('info','Success','Removed participant successfully');
            }

            await this.getMeetParticipants();
            this.participatesToParent();
            
            this.participates_attended = this.$store.getters.members_attended;
            this.$store.state.loader_popup = false;
        },

        participatesToParent() {
            bus.$emit('all_participates', this.meeting_participates)
        },

        mail_exist_in_participates(mail){
            return this.meeting_participates.find(attendee => attendee.email == mail);
        },

        save_attendance(e){
            this.$store.commit('updateAttendance',{"participant_id": e.target.value,"attendance_status": e.target.checked});
            this.toast('info','Success','Attendance updated successfully');
        },

        async addGuest(){
            if(this.add_mem_firstname != "" && this.add_mem_lastname !="" && this.add_mem_mail){
                let add_member_data = {
                    "org_id": this.$store.state.org_id,
                    "meeting_id":this.meeting_data._id,
                    "first_name": this.add_mem_firstname,
                    "last_name": this.add_mem_lastname,
                    'full_name' : this.add_mem_firstname + ' ' + this.add_mem_lastname+"&nbsp;<br>"+"<small class='grey'>"+this.add_mem_mail+"</small>",
                    "email": this.add_mem_mail,
                    
                }
               
                let mail_exist_in_participates =  this.mail_exist_in_participates(this.add_mem_mail);
               
                let mail_exist_in_members =  this.mail_exist_in_members(this.add_mem_mail);

                if(!mail_exist_in_members && !mail_exist_in_participates){
                    await this.$store.dispatch('addParticipatestoMeeting',[this.$store.state.api,add_member_data]);
                    if(Object.keys(this.$store.state.added_participate).length > 0){   
                        await this.getMeetParticipants();
                        this.org_members_selected();
                        this.$store.state.added_participate.html_name = this.add_mem_firstname + ' ' + this.add_mem_lastname+"&nbsp;<br>"+"<small class='grey'>"+this.add_mem_mail+"</small>";
                    }
                    this.$store.commit('updateAttendance',{"participant_id": this.$store.state.added_participate._id,"attendance_status": true});
                    this.participates_attended.push(this.$store.state.added_participate._id);
                    sessionStorage.setItem('members_attendance', JSON.stringify(this.participates_attended));
                    this.toast('info','Success','Guest added successfully');
                    this.add_member_error = "";
                    this.add_mem_firstname = "";
                    this.add_mem_lastname = "";
                    this.add_mem_mail = "";
                   
                    await this.$store.dispatch('getOrgMembers',[this.$store.state.api,this.$store.state.org_id ]);
                    this.participatesToParent();

                }else if(mail_exist_in_participates && mail_exist_in_participates.email){
                    this.add_member_error = "Member already  exists";
                    return false;
                }else{
                    this.add_member_error = "Member already exists";
                    return false;
                }
            }
        },

    }
}